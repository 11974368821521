<template>
  <div>
    <loader-info v-if="loading"/>
    <div v-if="!loading ">
      <b-card
        v-if="checkPlafonds"
        class="mb-4"
      >
        <template #header>
          <card-header
            title="Affidamenti per tipologia"
          />
        </template>

        <div v-if="success" class="mx-n3">
          <b-table-simple striped responsive>
            <b-thead>
              <b-tr>
                <b-th>Tipologia</b-th>
                <b-th>Utilizzo</b-th>
                <b-th>Accordato Totale</b-th>
                <b-th>% Utilizzo</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <single-type-plafond-usage
                v-for="item in plafondTypes"
                :key="item"
                :type="item" :plafond="usages(item)"
              />
            </b-tbody>
          </b-table-simple>
        </div>
        <div v-if="!success">
          Non è stato possibile recupare i totali degli affidamenti presenti
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { isNotEmpty } from '../../utils/validators';

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const SingleTypePlafondUsage = () => import('@/components/userPlafond/SingleTypePlafondUsage.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'PlafondsUsage',
  components: { SingleTypePlafondUsage, LoaderInfo, CardHeader },
  props: {
    companyId: String,
  },
  data() {
    return {
      loading: false,
      success: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    totals() {
      return this.$store.getters['invoice/companyPlafondTypeUsages'];
    },
    plafondTypes() {
      return Object.keys(this.totals || {});
    },
    checkPlafonds() {
      // console.log('this.plafondTypes', this.plafondTypes);
      if (this.plafondTypes.length > 0) {
        return isNotEmpty(this.plafondTypes);
      }
      return false;
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.success = false;
      this.$store.dispatch('invoice/plafondsUsage', this.$props.companyId)
        .then((singleUsage) => {
          console.debug('singleUsage', singleUsage);
          // const usage = {};
          // singleUsage.forEach((row) => {
          //   if (!usage[row.plafondType]) {
          //     usage[row.plafondType] = {
          //       usage: 0,
          //       totalPlafond: 0,
          //     };
          //   }
          //   usage[row.plafondType].usage += row.usage;
          //   usage[row.plafondType].totalPlafond += row.globalPlafondLimit;
          // });
          // this.totals = usage;
          this.success = true;
          this.loading = false;
        })
        .catch((e) => {
          console.error('Loading usage failed', e);
          this.loading = false;
        });
    },
    usages(type) {
      return (this.totals || {})[type] || {};
    },
  },
};
</script>

<style scoped>

</style>
